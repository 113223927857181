.input-bar {
  /* background-color: coral; */
  border: 1px solid #192A4D;
  border-radius: 5px;
}
.search-icon {
  background-color: #192A4D;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  transition: 0.4s ease-in;
}

.form-control-borderless {
  border: none;
}

.search-bar {
 padding: 1px;
}

.form-control-borderless:hover,
.form-control-borderless:active,
.form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.search-data {
  margin: 0 auto;
  font-size: 40px;
  /* height: 200px !important; */
  display: flex;
  flex-direction: column;
  width: 80vw;
  
}
/* .search-data-result{
  overflow-x: scroll;
} */

.eventsearch:hover {
  background-color: #192A4D;
  color: #ffffff;
}

.follow-button:hover,
.follow-button:hover i {
  background-color: #fff;
  color: #192A4D;
}
/* .follow-button:hover i {

} */

.sync-event {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 20px 20px 0;
  cursor: pointer;
}

.sync-event-round {
  height: 70px;
  background: #192A4D;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
}

.grid-item span {
  font-size: 14px;
}


/* Media */

/* @media only screen and (max-width: 1024px) {
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    display: none;
  }
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6) {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    display: none;
  }
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6) {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    display: none;
  }
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    display: none;
  }

  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    display: none;
  }
} */
