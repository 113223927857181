.report9 {
  /* width: 90vw; */
  overflow: scroll;

}
.report9 table{
  height: 100px !important;
  display: table;
}
.report9 table thead tr th{
  position: sticky;
  top: 0;
}

/* .report9 table tbody{
  max-height: 10px !important;
  overflow-y: scroll;
} */