#first-circle,
#second-circle,
#third-circle {
  animation: wheel 2s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

#dashboard-wave {
  /* display: none; */
  animation: jump 2s ease-in-out infinite alternate;
  transform-origin: top;
  /* transform-box: fill-box; */
}

@keyframes wheel {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@keyframes jump {
  from {
    transform: rotateX(-20deg);
  }

  to {
    transform: rotateX(10deg);
  }
}
