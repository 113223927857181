@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap');


 /* *{
  padding: 0;
  margin: 0;
  font-family: 'Cabin', sans-serif;
}  */

.theme-dark {
  background-color: #192A4D !important;
  color: #fff !important;
  border-bottom: 1px solid #192A4D;
}
.theme-light {
  /* background-color: #0779e4 !important;
  color: #fff !important;
  border-bottom: 1px solid #0779e4; */
  background-color: #192A4D !important;
  color: #fff !important;
  border-bottom: 1px solid #192A4D;
}
.bg-primary{
  background-color: #192A4D !important;
  color: #fff !important;
}


.bg-darker2{
  background-color: #fff !important;
  color: #192A4D !important;
}
.bg-lighter2{
  background-color: #192A4D !important;
  color: #fff !important;
}

.bg2-darker2{
  background-color: #0c1933 !important;
  color: #fff !important;
}

.bg-default {
  background-color: #192A4D !important;
  color: #fff !important;
}

.custom-toggle input:checked + .custom-toggle-slider:before{
  background:#06e45f !important
}
.custom-toggle input:checked + .custom-toggle-slider{
  border:1px solid #06e45f !important
}



/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

