/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
} */

.loginnew-wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}

.loginnew-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
}

/*  */

.loginnew-bms-logo {
  width: 60%;
  margin-bottom: 3rem;
  transform: translateX(1200px);
  animation: left-img-animate 1s forwards ease-in;
}

.loginnew-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: translateY(-1200px);
  animation: left-img-animate 1s forwards ease-in;
}

.loginnew-login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.loginnew-img img {
  width: 500px;
}

.loginnew-form {
  width: 360px;
}

.loginnew-login-content img {
  height: 100px;
}

.loginnew-login-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.loginnew-login-content .loginnew-input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.loginnew-login-content .loginnew-input-div.one {
  margin-top: 0;
}

.loginnew-i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginnew-i i {
  transition: 0.3s;
}

.loginnew-input-div > div {
  position: relative;
  height: 45px;
}

.loginnew-input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}

.loginnew-input-div:before,
.loginnew-input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #192A4D;
  transition: 0.4s;
}

.loginnew-input-div:before {
  right: 50%;
}

.loginnew-input-div:after {
  left: 50%;
}

.loginnew-input-div.focus:before,
.loginnew-input-div.focus:after {
  width: 50%;
  color: #333;
}

.loginnew-input-div.focus > div > h5 {
  top: -8px;
  font-size: 15px;
}

.loginnew-input-div.focus > .loginnew-i > i {
  color: #192A4D;
}

.loginnew-input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: "poppins", sans-serif;
}

.loginnew-input-div.pass {
  margin-bottom: 4px;
}

/* a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: var(--primary);
} */

.loginnew-btn {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
  /* background-image: linear-gradient(
    to right,
    var(--primary),
    var(--primary),
    var(--primary)
  ); */
  background-image: linear-gradient(to right, #0879e4, #192A4D, #0879e4);
  /* background-color: #192A4D; */
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  margin: 2rem 0;
  cursor: pointer;
  transition: 0.5s;
}
.loginnew-btn:hover {
  background-position: right;
}

/* Keyframes */

@keyframes left-img-animate {
  to {
    transform: translateY(0);
  }
}

/* Media */
@media screen and (max-width: 1050px) {
  .loginnew-container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .loginnew-form {
    width: 290px;
  }

  .loginnew-login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .loginnew-img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  /* .bms-logo {
    width: 50vw;
  } */

  .loginnew-container {
    grid-template-columns: 1fr;
  }

  .loginnew-img {
    display: none;
  }

  .loginnew-wave {
    display: none;
  }

  .loginnew-login-content {
    justify-content: center;
  }
}
